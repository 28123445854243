@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://use.fontawesome.com/releases/v5.13.0/css/all.css);
.Home_splash__3mn9c {
    width: 100%;
    height: calc(100vh - 66px);   /* 66 pixels is the height of the navbar */
    overflow: hidden;
}

.Home_splashitems__kSjLT {
    position: relative;
    top: -295vh;
}

.Home_splashtext__3n56k {
    margin: 4em 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home_splashtext__3n56k h1 {
    margin: 0.5em 0;
}

.Home_splashtext__3n56k h3 {
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    text-align: center;
}

.Home_buttons__122ka {
    text-align: center;
}

.Home_buttons__122ka a {
    display: inline-block;
    margin: 1em;
}

.Home_bg__1lXo3 {
    -webkit-animation: Home_slide__NlrKL 3s ease-in-out infinite alternate;
            animation: Home_slide__NlrKL 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #daa520 50%, #dc143c 50%);
    opacity: 0.5;
    width: 200%;
    height: 100vh;
    left: -50%;
    right: -50%;
    position: relative;
    overflow: hidden;
    z-index: -1;
}

.Home_bg2__1FbNb {
    animation-direction: alternate-reverse;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    top: -100vh;
}

.Home_bg3__1Eq0o {
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    top: -200vh;
}

@-webkit-keyframes Home_slide__NlrKL {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}

@keyframes Home_slide__NlrKL {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}

.Home_raised__8UE9Y {
    box-shadow: 10px 10px 3px rgba(0, 0, 0, 0.35);
}

.Home_features__2sH9N {
    margin-top: 4em;
}

.Home_features__2sH9N h2 {
    text-align: center;
}

.Home_features__2sH9N > div {
    margin: 4em 0;
}

.Home_gettingstarted__3MI6o {
    margin-top: 4em;
    padding: 4em 0;
    text-align: center;
    background-color: rgb(50, 53, 54);
}

code {
    color: #ff5858;
    background-color: #2c2f33;
    padding: 0.125em;
}

body img {
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    -o-user-select: none;
    user-select: none;          
}
.NotFound_error__31Vmq {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.Stats_titleandglance__3oLDT {
    text-align: center;
}

.Stats_titleandglance__3oLDT h2 {
    margin: 2em 0;
}

.Stats_titleandglance__3oLDT>div {
    text-align: center;
}

.Stats_cards__1Q0s1 {
    margin: 2em 0;
}

.Stats_cards__1Q0s1>div {
    margin-bottom: 1em;
}
/* Fonts */

/* Global defaults */
html, body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
}

body {
    background-color: transparent;
}

html {
    background-color: #2c2f33;
}

body::-webkit-scrollbar {
    display: none;
}

h1, h2, h3, h4, h5, h6, p {
    color: #fff;
    font-family: 'Varela Round', sans-serif;
}

/* Navbar */
.navbar-dark {
    background-color: #23272a !important; /* !important cause bootstrap does it too :( */
}

#basic-navbar-nav .nav-link {
    color: #fff;
    font-weight: 500;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

/* Footer */
#root {
    position: relative;
    min-height: 100vh;
    padding-bottom: 4rem;
}

#footer {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0px;
    width: 100%;

    height: 4rem;
    background-color: #23272a;
    text-align: center;
    line-height: 3rem;
}

/* Set the hug text to the default font since it looks strange on Varela Round */
#hug {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
