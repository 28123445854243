/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

/* Global defaults */
html, body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
}

body {
    background-color: transparent;
}

html {
    background-color: #2c2f33;
}

body::-webkit-scrollbar {
    display: none;
}

h1, h2, h3, h4, h5, h6, p {
    color: #fff;
    font-family: 'Varela Round', sans-serif;
}

@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

/* Navbar */
.navbar-dark {
    background-color: #23272a !important; /* !important cause bootstrap does it too :( */
}

#basic-navbar-nav .nav-link {
    color: #fff;
    font-weight: 500;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

/* Footer */
#root {
    position: relative;
    min-height: 100vh;
    padding-bottom: 4rem;
}

#footer {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0px;
    width: 100%;

    height: 4rem;
    background-color: #23272a;
    text-align: center;
    line-height: 3rem;
}

/* Set the hug text to the default font since it looks strange on Varela Round */
#hug {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}